module.exports = [{
      plugin: require('../node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/src/components/common/Layout/index.jsx"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#00A2FF","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-51525032-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CloudBuilder","short_name":"CloudBuilder","start_url":"/","background_color":"#00A2FF","theme_color":"#00A2FF","display":"minimal-ui","icon":"./static/favicon/favicon-512.png"},
    }]
